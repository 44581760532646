import { Injectable } from '@angular/core';
import { HttpAuth } from '@services/httpauth/http-auth.service';
import { Observable } from 'rxjs';
import {
	AdminListDto,
	ApplicationDetailsDto,
	ApplicationFiltersDto,
	ApplicationListRowDto,
	ApplicationNoteDto,
	ApplicationChangeHistoryEntryDto,
	PageDto,
	QuestionnaireFilesDto,
	QuestionnaireGeneratedPdfDto,
	QuestionnaireFilesForUserListDto,
	QuestionnaireFilesForUserUpdateDto
} from '@models/models';
import { ApplicationStatusType } from '@interfaces/enums/application-status-type';
import { environment } from '@env';

@Injectable({
	providedIn: 'root'
})
export class ApplicationsService {
	constructor(private readonly http: HttpAuth) {}

	get downloadEmailsUrl(): string {
		return environment.downloadEmailsUrl;
	}

	getApplicationList(
		filters: ApplicationFiltersDto = {}
	): Observable<PageDto<ApplicationListRowDto>> {
		return this.http.get('/applications', filters);
	}

	getApplication(id: number): Observable<ApplicationDetailsDto> {
		return this.http.get('/application/' + id);
	}

	removeApplication(id: number): Observable<null> {
		return this.http.delete('/application/' + id);
	}

	putApplicationAssign(
		applicationId: number,
		adminId: number
	): Observable<AdminListDto> {
		return this.http.put(`/applications/${applicationId}/assign-to/${adminId}`);
	}

	putApplicationNotes(
		applicationId: number,
		note: string
	): Observable<ApplicationNoteDto> {
		return this.http.post(`/application/${applicationId}/notes`, { note });
	}

	putApplicationStatus(
		applicationId: number,
		status: ApplicationStatusType
	): Observable<ApplicationDetailsDto> {
		return this.http.put(`/application/${applicationId}/status`, { status });
	}

	getApplicationFiles(
		applicationId: number
	): Observable<QuestionnaireFilesDto[]> {
		return this.http.get(`/application/${applicationId}/files`);
	}

	setDocumentsToBeAttachedForGivenUser(
		body: QuestionnaireFilesForUserUpdateDto
	): Observable<QuestionnaireFilesForUserListDto> {
		return this.http.put(`/questionnaire-documents-set-for-user`, body);
	}

	getDocumentsForSelectedUser(
		userId: number
	): Observable<QuestionnaireFilesForUserListDto> {
		return this.http.get(`/questionnaire-documents-get-for-user/${userId}`);
	}

	getApplicationChangeHistory(
		applicationId: number
	): Observable<ApplicationChangeHistoryEntryDto[]> {
		return this.http.get(`/application/${applicationId}/history`);
	}

	// Admin Application PDFs
	generatePdfsForUser(
		questionnaireId: number
	): Observable<QuestionnaireGeneratedPdfDto> {
		return this.http.post(`/application-pdfs/${questionnaireId}`);
	}

	getPdfsForUser(
		questionnaireId: number
	): Observable<QuestionnaireGeneratedPdfDto> {
		return this.http.get(`/application-pdfs/${questionnaireId}`);
	}

	uploadPdfForUser(
		questionnaireId: number,
		pdfName: string,
		file: File
	): Observable<QuestionnaireGeneratedPdfDto> {
		const formData: FormData = new FormData();
		formData.append('file', file, file.name);
		return this.http.post(
			`/application-pdfs/${questionnaireId}/${pdfName}`,
			formData
		);
	}

	deletePdfForUser(
		questionnaireId: number,
		pdfName: string
	): Observable<QuestionnaireGeneratedPdfDto> {
		return this.http.delete(`/application-pdfs/${questionnaireId}/${pdfName}`);
	}

	updateDocumentsToggleChange(
		userId: number,
		toggleValue: boolean
	): Observable<null> {
		return this.http.patch(
			`/user/documentstoggle/${userId}?toggleValue=${toggleValue}`
		);
	}
}
