import { Injectable } from '@angular/core';
import { HttpAuth } from '@services/httpauth/http-auth.service';
import { UserFilterDto } from '@models/userFilterDto';
import { PageDto } from '@models/pageDto';
import { UserOnListDto } from '@models/userOnListDto';
import { Observable } from 'rxjs';
import { AdminListDto } from '@models/adminListDto';

@Injectable({
	providedIn: 'root'
})
export class BackOfficeService {
	constructor(private http: HttpAuth) {}

	getUserList(filters: UserFilterDto): Observable<PageDto<UserOnListDto>> {
		return this.http.get('/users', filters);
	}

	getAdminList(): Observable<AdminListDto[]> {
		return this.http.get('/admins');
	}

	saveUser(data) {
		return this.http.post('/user', data);
	}

	deleteUser(id) {
		return this.http.delete(`/user/${id}`);
	}

	changePassword(password, userId) {
		return this.http.put(`/user/${userId}/change-password`, { password });
	}
}
