import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	ChatMessageDto,
	ChatMessagesDto,
	ChatThreadDto,
	ChatThreadWithMessageDto,
	MessageTemplateDto,
	NewChatMessageDto,
	NewMessageTemplateDto
} from '@models/chatDto';
import { HttpAuth } from '@services/httpauth/http-auth.service';

@Injectable({
	providedIn: 'root'
})
export class MessagesService {
	constructor(private http: HttpAuth) {}

	getThreads(): Observable<ChatThreadDto> {
		return this.http.get(`/chat/threads`);
	}

	getAllThreadMessages(threadId: number): Observable<ChatMessagesDto> {
		return this.http.get(`/chat/thread/${threadId}`);
	}

	getApplicationMessages(
		threadId: number,
		applicationId: number
	): Observable<ChatMessageDto> {
		return this.http.get(
			`/chat/thread?threadId=${threadId}&applicationId=${applicationId}`
		);
	}

	newMessage(
		userId: number,
		body: NewChatMessageDto
	): Observable<ChatThreadWithMessageDto> {
		return this.http.post(`/chat/user/${userId}`, body);
	}

	sendMessage(
		threadId: number,
		body: NewChatMessageDto
	): Observable<ChatMessageDto> {
		return this.http.post(`/chat/thread/${threadId}`, body);
	}

	getMessageTemplates(): Observable<MessageTemplateDto[]> {
		return this.http.get(`/message-templates`);
	}

	addMessageTemplate(
		body: NewMessageTemplateDto
	): Observable<MessageTemplateDto> {
		return this.http.post(`/message-template`, body);
	}

	removeMessageTemplate(id: number): Observable<null> {
		return this.http.delete(`/message-template/${id}`);
	}
}
