export interface UserDto {
	avatarUrl?: string;
	email: string;
	firstName: string;
	id: number;
	lastName: string;
	role: UserDto.RoleEnum;
	uploaded: boolean;
	username: string;
}
export namespace UserDto {
	export type RoleEnum = 'ADMIN' | 'USER' | 'BACK_OFFICE' | 'ATTORNEY';
	export const RoleEnum = {
		ADMIN: 'ADMIN' as RoleEnum,
		USER: 'USER' as RoleEnum,
		BACK_OFFICE: 'BACK_OFFICE' as RoleEnum,
		ATTORNEY: 'ATTORNEY' as RoleEnum
	};
}
