import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { OnConfirmDeactivate } from '@models/models';

@Injectable()
export class ConfirmDeactivateGuard
	implements CanDeactivate<OnConfirmDeactivate> {
	canDeactivate(target: OnConfirmDeactivate) {
		if (target.confirmClose) {
			return target.confirmPromise();
		}
		return true;
	}
}
