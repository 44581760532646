import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Resolve,
	RouterStateSnapshot
} from '@angular/router';
import { ApplicationsService } from '../applications.service';
import { ApplicationDetailsDto } from '@models/applicationDetailsDto';

@Injectable({
	providedIn: 'root'
})
export class ApplicationResolveService
	implements Resolve<ApplicationDetailsDto> {
	constructor(private service: ApplicationsService) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.service.getApplication(route.params.applicationId);
	}
}
